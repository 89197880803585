import { get, post } from "@ts/Util/http";
import { currentUserLocale } from "@ts/Util/i18n";
import { csrfToken } from "@ts/Util/security";
import { redirect } from "@ts/Util/http";
import { getMixpanelDistinctID } from "@ts/Util/analytics";
export const FACEBOOK_DEFAULT_VERSION = 'v16.0';
export const FACEBOOK_DEFAULT_SCOPE = 'email';
export const FACEBOOK_DEFAULT_FIELDS = 'email,first_name,last_name';
export const createFacebookService = () => {
    const _window = window;
    const locale = currentUserLocale() ?? 'en-US';
    const loginByZumba = async (payload) => {
        const body = {
            emailExpected: payload.emailExpected ?? '',
            createSSOUser: payload.createSSOUser ?? false,
            'access_token': payload.accessToken,
            'get_newsletter': true,
            'terms_of_use': true,
            locale: locale.replace('-', '_'),
            'mixpanelDistinctID': getMixpanelDistinctID(),
        };
        return new Promise((resolve, reject) => {
            post(`/${locale}/user/loginWithFacebookCredentials`, csrfToken(), body)
                .then(async (response) => {
                const jsonResponse = await response.json();
                jsonResponse.success ? resolve(jsonResponse) : reject({ jsonResponse, body });
            });
        });
    };
    const signUpWithEmail = async (payload) => {
        const body = {
            email: payload.email,
            'access_token': payload.accessToken,
            'get_newsletter': true,
            'terms_of_use': true,
            locale: locale.replace('-', '_'),
            'mixpanelDistinctID': getMixpanelDistinctID(),
        };
        const errorFromResponse = (jsonResponse) => {
            if (jsonResponse.error) {
                return new FacebookAccountLinkingException(jsonResponse.error);
            }
            return new FacebookLoginException('Something went wrong', jsonResponse);
        };
        return new Promise((resolve, reject) => {
            post(`/${locale}/user/signUpWithEmailAndFacebookCredentials`, csrfToken(), body)
                .then((response) => response.json())
                .then((jsonResponse) => jsonResponse.success ?
                resolve(jsonResponse) :
                reject(errorFromResponse(jsonResponse)))
                .catch(({ jsonResponse }) => {
                reject(errorFromResponse(jsonResponse));
            });
        });
    };
    const getLoginStatus = () => {
        return new Promise(resolve => _window.FB.getLoginStatus((response) => resolve(response)));
    };
    const me = (fields) => {
        return new Promise((resolve, reject) => {
            _window.FB.api('/me', { fields }, (user) => {
                if (user.error) {
                    reject(new Error(user.error.message));
                    return;
                }
                resolve(user);
            });
        });
    };
    const login = (options) => {
        const scope = options.scope ?? FACEBOOK_DEFAULT_SCOPE;
        const fields = options.fields ?? FACEBOOK_DEFAULT_FIELDS;
        return new Promise((resolve, reject) => {
            _window.FB.login(({ authResponse, status }) => {
                if (status !== 'connected') {
                    reject('Error while trying to connect with Facebook');
                    return;
                }
                const body = {
                    ...authResponse,
                    emailExpected: options.emailExpected ?? '',
                    createSSOUser: options.createSSOUser ?? false,
                };
                let userFields = null;
                me(fields)
                    .then((user) => {
                    userFields = user;
                    return loginByZumba({ user, ...body });
                })
                    .then((response) => resolve(response))
                    .catch(({ jsonResponse, body }) => {
                    if (jsonResponse.errorType) {
                        if (jsonResponse.errorType === 'missing_email' && userFields !== null) {
                            reject(new UserMailMissingException(userFields));
                            return;
                        }
                        if (jsonResponse.errorType === 'missing_user_profile') {
                            reject(new UserMissingException());
                            return;
                        }
                    }
                    if (jsonResponse.error) {
                        reject(new FacebookAccountLinkingException(jsonResponse.error));
                        return;
                    }
                    reject(new FacebookLoginException('Something went wrong', jsonResponse));
                });
            }, { scope });
        });
    };
    return {
        redirect,
        loginByZumba,
        signUpWithEmail,
        getLoginStatus,
        me,
        initSdk: (options) => {
            if (!options.appId) {
                throw new Error('Missing App ID, please load in the view');
            }
            return new Promise(resolve => {
                _window.fbAsyncInit = () => {
                    _window.FB.init({
                        appId: options.appId,
                        version: options.version ?? FACEBOOK_DEFAULT_VERSION,
                        cookie: true,
                        xfbml: true
                    });
                    getLoginStatus().then((response) => resolve(response));
                };
                (function (d, s, id) {
                    let js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = `https://connect.facebook.net/${locale}/sdk.js`;
                    fjs?.parentNode?.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            });
        },
        login,
        updateUserInfoCache: (user) => {
            if (!user.id) {
                throw new Error('Missing user ID');
            }
            return new Promise((resolve, reject) => {
                post(`/${locale}/user/facebookMe`, csrfToken(), { user })
                    .then(async (response) => resolve(await response.json()))
                    .catch((error) => reject(error));
            });
        },
        emailExists: (email) => {
            return new Promise((resolve, reject) => {
                get(`/${locale}/user/emailExists`, csrfToken(), { email })
                    .then(async (response) => {
                    const { exists } = await response.json();
                    if (exists) {
                        reject(new EmailExistsException());
                        return;
                    }
                    resolve(exists);
                })
                    .catch((error) => reject(error));
            });
        }
    };
};
export class UserMailMissingException extends Error {
    constructor(user, message = 'User email is missing') {
        super(message);
        this._user = user;
    }
    get user() {
        return this._user;
    }
}
export class EmailExistsException extends Error {
    constructor(message = 'Email already exists') {
        super(message);
    }
}
export class FacebookAccountLinkingException extends Error {
    constructor(message) {
        super(message);
    }
}
export class FacebookLoginException extends Error {
    constructor(message = 'Error while trying to login with Facebook', body) {
        super(message);
        this._body = body;
    }
    get body() {
        return this._body;
    }
}
export class UserMissingException extends Error {
    constructor(message = 'User is missing') {
        super(message);
    }
}
